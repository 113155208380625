/** ------------------------------------------- Gaf Main Func (this.js 대상)  Start ------------------------------------------- */
import Gaf from '@/ui/pi/common/Gaf.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
let store

export default {
  setStore (name) {
    store = window.vue.getStore(name)
    if (store) {
      console.log(store, '★★★★★★★★★★★★GafMainFunc Store set Completed')
    }
  },
  // Object 복사
  copyJson (json) {
    return JSON.parse(JSON.stringify(json))
  },
  // 보기납기 기간 구하기
  calcPrd  (type, val, age) {
    Gaf.d('this.calcPrd input : ' + type + ':' + val + ':' + age)
    let typeVal = parseInt(type, 10)

    let out = val
    if (typeVal === 2) {
      Gaf.d('chk1')
      out = val - age
    } else if (typeVal === 1) {
      Gaf.d('chk2')
      out = val
    } else if (typeVal === 0) {
      Gaf.d('chk3')
      out = '0'
    } else if (typeVal === 99) {
      Gaf.d('chk4')
      out = 999
    }
    Gaf.d('this.calcPrd output : ', out)
    return out
  },
  // 보험만기일자 구하기
  calcExpYmd  (inprdVl, stndYmd) {
    Gaf.d('this.calcExpYmd input    ( inprdVl => ' + inprdVl + ' , stndYmd =>' + stndYmd + ' )')

    // 8자리 날짜로 변환
    let date8 = this.convertDateToLength8(stndYmd)

    let tempStndYear = date8.substr(0, 4)
    let tempStndDate = date8.substr(date8.length - 4, 4)

    Gaf.d('tempStndYear : ' + tempStndYear + '  ,  tempStndDate : ' + tempStndDate)

    let out = ''
    if (inprdVl === 999) {
      out = '29980322' // SAP max 일자로 셋팅
    } else {
      let tempCalcYear = Number(tempStndYear) + Number(inprdVl)

      if (tempStndDate === '0229') {
        if ((tempCalcYear % 4 === 0) && (tempCalcYear % 100 !== 0) || (tempCalcYear % 400 === 0)) {
          Gaf.d('0229 그대로 처리함')
        } else {
          Gaf.d('0229 => 0228 로 변환됨')
          tempStndDate = '0228'
        }
      }

      out = '' + tempCalcYear + tempStndDate
    }

    Gaf.d('this.calcExpYmd output : ', out)
    return out
  },
  getDateString_DDMM  (dateString) { // YYYYMMDD => DDMM 날짜형식으로 변환
    let date8 = this.convertDateToLength8(dateString)
    return date8.substr(6, 2) + date8.substr(4, 2)
  },

  convertDateToLength8  (dateString) {
    let out

    if (dateString.length === 10) {
      let tempSplit
      if (dateString.indexOf('-') >= 0) {
        tempSplit = dateString.split('-')
      } else if (dateString.indexOf('.') >= 0) {
        tempSplit = dateString.split('.')
      } else {
        Gaf.d('[this.convertDateToLength8]  날짜형식 에러!!! 8자리로 변환이 불가합니다. => [' + dateString + ']')
        return 'error'
      }

      if (tempSplit[0] && tempSplit[1] && tempSplit[2]) {
        out = tempSplit[0] + tempSplit[1] + tempSplit[2]
      } else {
        Gaf.d('[this.convertDateToLength8]  날짜형식 에러!!! YYYY?MM?DD 형식이 아닙니다. => [' + dateString + ']')
        return 'error'
      }
    } else if (dateString.length === 8) {
      out = dateString
    } else {
      Gaf.d('[this.convertDateToLength8] 날짜형식 에러!!! 10자리 또는 8자리만 변환 가능합니다. => [' + dateString + ']')
      return 'error'
    }

    return out
  },

  // 주민번호로 성별구하기
  calcGender  (rrn) {
    if (rrn === '0000003000000') {
      return '1'
    }

    if ( '1,3,5,7'.indexOf(rrn.substr(6, 1)) >= 0 ) {
      return '1'
    } else {
      return '2'
    }
  },

  // 주민번호로 생년월일 구하기
  calcBirthDate  (rrn) {
    if (rrn === '0000003000000') {
      return '00010101'
    }

    let birthDate = ''
    if ( '1,2,5,6'.indexOf(rrn.substr(6, 1)) >= 0 ) {
      birthDate = '19' + rrn.substr(0, 6)
    } else {
      birthDate = '20' + rrn.substr(0, 6)
    }
    return birthDate
  },

  // 주민번호 연령 구하기 (mode => "insurance":보험연령, "legal":법정연령(만연령))
  calcAge  (rrn, stdYmd, mode) {
    stdYmd = this.convertDateToLength8(stdYmd)

    Gaf.d('rrn : ' + rrn)
    Gaf.d('stdYmd : ' + stdYmd)
    Gaf.d('mode : ' + mode)

    if (mode !== 'insurance' && mode !== 'legal') {
      console.error('주민번호 연령 구하기 mode 값이 올바르지 않습니다. (입력된 mode:' + mode + ')')
      return false
    }

    let birthDate = ''

    // 태아 또는 잘못된 주민번호는 0으로 리턴
    if (rrn === '0000003000000' || rrn.length !== 13 || stdYmd.length !== 8) {
      Gaf.d('태아 또는 잘못된 주민번호는 0으로 리턴')
      return 0
    } else {
      birthDate = this.calcBirthDate(rrn)
    }

    let fullMonths = this.doGetMonthsDiff(birthDate, stdYmd)
    Gaf.d('fullMonths => ' + fullMonths)

    let years = fullMonths / 12
    let months = fullMonths % 12
    let age = 0

    if (mode === 'insurance' && months > 5) {
      age = years + 1
    } else {
      age = years
    }
    return Math.floor(age)
  },

  doGetMonthsDiff (beginDate, endDate) {
    let dates = Number(beginDate) - Number(endDate)
    let factor = 1
    if (dates > 0) {
      factor = -1
      let tempEndDate = endDate
      endDate = beginDate
      beginDate = tempEndDate
    }

    let years = Number(endDate.substr(0, 4)) - Number(beginDate.substr(0, 4))
    let months = Number(endDate.substr(4, 2)) - Number(beginDate.substr(4, 2))
    let days = Number(endDate.substr(6, 2)) - Number(beginDate.substr(6, 2))

    if (years === 0) {
      if (months >= 0) {
        return (years * 12 + months + (days < 0 ? -1 : 0)) * factor
      } else {
        return (years * 12 + months + (days > 0 ? 1 : 0)) * factor
      }
    }
    if (years > 0) {
      return (years * 12 + months + (days < 0 ? -1 : 0)) * factor
    } else {
      return (years * 12 + months + (days > 0 ? 1 : 0)) * factor
    }
  },

  // Template ID 구하기 by refId (from Prod Info)
  getProdStructTemplateId  (refId) {
    let out = store.getters.getTObj('prodInfo').prodStructList.filter((item) => { return item.refId === refId })
    return out
  },

  // 관계자명 구하기
  makeRltnrText  (rltnrType, chldSno) {
    let out = ''
    if (rltnrType === '21') {
      out = '주피'
    } else if (rltnrType === '23') {
      out = '종피'
    } else if (rltnrType === '24') {
      out = '자녀' + chldSno
    } else if (rltnrType === '11') {
      out = '계약자'
    }

    return out
  },

  /**
 * PNLPQL00109 : 보기/납기 Text 생성(유형코드, 유형값)
 */
  makePrdText  (typCd, typVal) {
    let out = ''
    let typeCdNum = Number(typCd)
    if (typeCdNum === 1) {
      out = typVal + '년'
    } else if (typeCdNum === 2) {
      out = typVal + '세'
    } else if (typeCdNum === 99) {
      out = '종신'
    } else if (typeCdNum === 0) {
      out = '일시납'
    }

    return out
  },

  // IFBC 값 구하기 (상품속성)
  getIfbcValue  (charId, templateId, inkey) {
    let arry = this.getIfbcValueArray(charId, templateId, inkey)
    // console.log(Gaf.getCharNames(), 'getCharNames')
    return arry && arry[0] ? arry[0] : ''
  },

  getIfbcValueArray  (charId, templateId, inkey) {
    // IFBC 셀렉션 Mode 적용시에 지정되지 않은 속성명이 오면 경고 메시지 처리
    if (store.getters.getIfbcSelectionYn === 'Y') {
      let findOk = false
      if ( (Gaf.getCharNames() && Gaf.getCharNames().length > 0 && Gaf.findChar(charId)) ||
      (Gaf.getAttrNames('POLPR') && Gaf.getAttrNames('POLPR').length > 0 && Gaf.findAttr(charId)) ||
      (Gaf.getAttrNames('COV') && Gaf.getAttrNames('COV').length > 0 && Gaf.findAttr(charId))
      ) {
        findOk = true
      }

      if (!findOk) {
        console.log('[경고] Template Id:' + templateId + ' 의 IFBC 명(' + charId + ') 은 Gaf 에 지정되지 않았습니다')
      }
    }

    let out = []
    let prodInfo = Gaf.getTObj('prodInfo')
    if (prodInfo.ifbcAttrList === undefined ) return out
    prodInfo.ifbcAttrList.forEach( (el) => {
      let nm
      let vl
      let cvl
      let ty
      let key
      if (!templateId || templateId === el.templateId) {
        // Attribute 먼저 찾는다 (RD 조회가 훨씬 많음)
        if (el.attrs && el.attrs.length > 0) {
          for (let k in el.attrs) {
            nm = el.attrs[k].nm
            vl = el.attrs[k].vl
            cvl = el.attrs[k].cvl
            ty = el.attrs[k].ty
            key = el.attrs[k].key

            // Key 값이 들어온경우 Key 가 일치하지 않으면 continue;
            if (inkey && key && inkey !== key) {
              continue
            }

            if (charId === nm) {
              let targetValue = cvl && cvl !== '' ? cvl : vl // 조건규칙 해석된 calcValue 를 우선해서 처리

              // 조건규칙 해석안된 값이면 경고메시지
              // if(targetValue.indexOf("#S") === 0){
              // console.alert("RD ("+nm+") 은 조건규칙 해석이 되지 않은 값 ("+targetValue+") 입니다.");
              // }

              if (ty === '002') {
                out = targetValue.split('~')
              } else if (ty === '003') {
                out = targetValue.split(',')
              } else {
                out[0] = targetValue
              }
              return false
            }
          }
        }

        // Char 찾기
        if (el.chars && el.chars.length > 0) {
          for (let k in el.chars) {
            nm = el.chars[k].nm
            vl = el.chars[k].vl
            cvl = el.chars[k].cvl
            ty = el.chars[k].ty
            key = el.chars[k].key

            // Key 값이 들어온경우 Key 가 일치하지 않으면 continue;
            if (inkey && key && inkey !== key) {
              continue
            }

            if (charId === nm) {
              let targetValue = cvl && cvl !== '' ? cvl : vl // 조건규칙 해석된 calcValue 를 우선해서 처리

              if (ty === '002') {
                out = targetValue.split('~')
              } else if (ty === '003') {
                out = targetValue.split(',')
              } else {
                out[0] = targetValue
              }
              return false
            }
          }
        }
      }
    })
    return out
  },
  // Child Struct 구하기 (templateId 에 "" 를 넣으면 refId 로 먼저 찾아지는것만 조회)
  getUndwChildProdStruct  (templateId, refId) {
    let undwGaf = document.getElementById('PGEPQL00053').contentWindow.Gaf
    let struct
    let out = []
    undwGaf.gafObject.tmodel.prodInfo.prodStructList.forEach(( el, i) => {
      if ((templateId === '' && refId && refId === el.refId) ||
(templateId === el.templateId && (!refId || refId === el.refId))
      ) {
        struct = el
        return false
      }
    })

    if (struct) {
      let childObj
      for (let i in struct.childStruct) {
        childObj = struct.childStruct[i]

        undwGaf.gafObject.tmodel.prodInfo.prodStructList.forEach( ( el, i) => {
          if (childObj.templateId === el.templateId) {
            out.push(el)
            return false
          }
        })
      }
    }

    return out
  },

  // Child Struct 구하기 (templateId 에 "" 를 넣으면 refId 로 먼저 찾아지는것만 조회)
  getChildProdStruct  (templateId, refId) {
    let struct = null
    let out = []
    store.getters.getTObj('prodInfo').prodStructList.forEach(( el, i) => {
      if ((templateId === '' && refId && refId === el.refId) ||
(templateId === el.templateId && (!refId || refId === el.refId))
      ) {
        struct = el
        return false
      }
    })

    if (struct) {
      let childObj
      for (let i in struct.childStruct) {
        childObj = struct.childStruct[i]

        store.getters.getTObj('prodInfo').prodStructList.forEach(( el, i) => {
          if (childObj.templateId === el.templateId) {
            out.push(el)
            return false
          }
        })
      }
    }

    return out
  },
  getProductIdForXml  (productId) {
    return productId.split(' ').join('_')
  },
  get10000Amt  (amt) {
    return amt ? this.getZeroFixedNum(amt) * 10000 : 0
  },
  set10000Amt  (amt) {
    return amt ? this.getZeroFixedNum(amt) / 10000 : 0
  },
  /**
 * 가입금액 단위로 곱하기(P모델에 셋팅하는 금액)
 * @param amt
 * @param unit
 * @returns
 */
  getUnitAmt  (amt, unit) {
    // 202005 외화보험 경우 SLI_CONFIG /COVERAGE LIST UNIT 사용안하고 1로 변경
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId
    if (currencyId === 'KRW') {
      if (Number(unit) % 10000 === 0) {
        unit = 10000
      } else if (Number(unit) % 1000 === 0) {
        unit = 1000
      } else {
        unit = 1
      }
    } else {
      unit = 1
    }
    return amt ? this.getZeroFixedNum(amt) * Number(unit) : 0
  },
  /**
    * 가입금액 단위로 나누기(UI에 표시되는 금액)
    * @param amt
    * @param unit
    * @returns
  */
  setUnitAmt  (amt, unit) {
    // 202005 외화보험 통화키 단위
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId
    let result = 0

    if (currencyId === 'KRW') {
      if (Number(unit) % 10000 === 0) {
        unit = 10000
      } else if (Number(unit) % 1000 === 0) {
        unit = 1000
      } else {
        unit = 1
      }
      result = amt ? this.getZeroFixedNum(amt) / Number(unit) : 0
    } else {
      result = amt
    }
    return result
  },
  /**
   * 소숫점표시
   * @param amt
   */
  getZeroFixedNum  (amt) {
    // 202005 외화보험 통화키
    // 단체는 abdapolicy가 없어서
    /*
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = 'KRW'
    if (!piCommonUtil.isEmpty2(abdapolicy)) {
      currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId
    }

    if (currencyId === 'USD') {
      return amt ? Number(amt).toFixed(2) : 0
    } else {
      return amt ? Number(amt).toFixed(0) : 0
    }
    */
    if ( Gaf.getGafObjByRefId('POLICY')[0] !== undefined && piCommonUtil.isObj(Gaf.getGafObjByRefId('POLICY')[0]) &&
      piCommonUtil.isObj(Gaf.getGafObjByRefId('POLICY')[0].currencyId) && Gaf.getGafObjByRefId('POLICY')[0].currencyId === 'USD') {
      return amt ? Number(Number(amt).toFixed(2)) : 0
    } else {
      return amt ? Number(Number(amt).toFixed(0)) : 0
    }
  },

  /**
 * 금액을 한글로 표현한다.
 * @param num (required) - 숫자
 * @param type (optional, default "HIGH" ) - 출력의 수위
 * @param delimChar(optional, default "") - 4자리마다 삽입될 구분 문자열
 * @returns
 */
  number2Kor  (num, type, delimChar) {
    if (isNaN(num)) return ''

    let baseNames = ['천', '백', '십', '']
    let levelNames = ['', '만', '억', '조',
      '경', '해', '자', '양',
      '구', '간', '정', '재',
      '극', '항하사', '아승기', '나유타',
      '불가사의', '무량대수']
    type = type || 'HIGH'
    delimChar = delimChar || ''

    let decimal = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']

    num = String(Number(num))

    let level = parseInt(num.length / baseNames.length, 10)
    let start = 0
    let end = num.length % baseNames.length // 0, 1, 2, 3
    let throwMsg = ''
    /* start validation */
    if ( num.length > 69 ) {
      throwMsg = 'too long number : ' + num
      throw throwMsg
    }
    if ( isNaN(num) ) {
      throwMsg = 'not a number form : ' + num
      throw throwMsg
    }
    if ( !isFinite(num) ) {
      throwMsg = 'not finite : ' + num
      throw throwMsg
    }
    /* end validation */

    if ( end === 0) { // in case the length of num is => 0, 4, 8, 12, ...
      end = Math.min(num.length, baseNames.length)
      level--
    } else {
      for ( let k = 0; k < baseNames.length - end; k++) {
        num = '0' + num
      }
      end = baseNames.length
    }
    let toKorString = ''
    let unitStr = ''
    let fns = {
      'LOW': (i, ch) => {
        if ( ch !== '0') {
          unitStr += ch
        } else if ( ch === '0' && unitStr.length > 0 ) {
          unitStr += ch
        }
      },
      'HALF': (i, ch) => {
        if ( ch !== '0' ) {
          unitStr += ch + baseNames[i]
        }
      },
      'HIGH': (i, ch) => {
        if ( ch !== '0') {
          unitStr += decimal[parseInt(ch, 10)] + baseNames[i]
        }
      }
    }

    while ( start < num.length ) {
      let partial = num.substring(start, end)
      unitStr = ''

      Array.from(partial).forEach((ch, i) => {
        fns[type](i, ch)
      })

      if (unitStr.length > 0) {
        toKorString += unitStr + levelNames[level] + delimChar
      }

      level--
      start = end
      end += baseNames.length
    }

    return toKorString
  },

  /**
 * 보험그리드의 특약 안내영역에 표시될 메세지 조합
 * @param covElem
 * @returns {String}
 */
  makeCovInfo  (covElem, stndAmt, zaAplcCalcTypVal ) {
    if (!piCommonUtil.isObj(covElem)) return ''

    // 상품정보원본이 있으면 원본으로..
    if (piCommonUtil.isObj(covElem.GafProductInfo)) {
      covElem = covElem.GafProductInfo
    }

    // let _covInfo = covElem.name + ' : '
    let _covInfo = ''
    // MIN~MAX:만원단위로 표시

    // 202005 외화보험 통화키 시작
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

    let _min = null
    let _max = null
    // 최소값
    if (piCommonUtil.isEmpty2(covElem.min) || Number(covElem.min) === 0) {
      _min = currencyId === 'KRW' ? '0.00001만원' : '$0.00001'
    } else {
      _min = this.calMinCovInsAmt(covElem, stndAmt, zaAplcCalcTypVal )
    }
    // 최대값 계산
    if (piCommonUtil.isEmpty2(covElem.max) || Number(covElem.max) === 0) {
      _max = currencyId === 'KRW' ? '99999999.9999만원' : '$99999999.9999'
    } else {
      _max = this.calMaxCovInsAmt(covElem, stndAmt, zaAplcCalcTypVal )
    }
    // 202005 외화보험 통화키 끝

    // 최소값이 최대값보다 크다면
    // if( _min > _max)
    if (Number(_min) > Number(_max)) {
      _covInfo = _covInfo + '가입불가'
    } else {
      if (_min >= 1) {
        _min = piCommonUtil.numberFormat( '#,###.', _min)
      }
      if (_max >= 1) {
        _max = piCommonUtil.numberFormat( '#,###.', _max)
      }
      // 202005 외화보험 통화단위 추가
      if (currencyId === 'KRW') {
        _min = _min + '만원'
        _max = _max + '만원'
      } else {
        _min = '$' + _min
        _max = '$' + _max
      }

      _covInfo = _covInfo + _min + ' ~ ' + _max

      let _unit = covElem.unit
      // 202005 외화보험 조건 추가
      /*
      if (piCommonUtil.isEmpty2(_unit) || currencyId !== 'KRW') {
        _unit = '1' // UNIT금액이 없는 경우 1원 단위
      }
      */
      let unitName1 = currencyId === 'KRW' ? '' : '$'
      let unitName2 = currencyId === 'KRW' ? '원' : ''
      let unitConv = currencyId === 'KRW' ? this.number2Kor(_unit) : piCommonUtil.numberFormat( '#,###.', _unit)
      _covInfo = _covInfo + ' [' + unitName1 + unitConv + unitName2 + ' 단위]'
      if (!piCommonUtil.isEmpty2(covElem.markTxt)) {
        _covInfo = _covInfo + ' (' + covElem.markTxt + ')'
      }
    }

    return _covInfo
  },

  /**
 * 특약의 가입금액 가능범위를 계산한다.
 */
  // 최소값
  calMinCovInsAmt  (covElem, stndAmt, zaAplcCalcTypVal ) {
    // 외화보험 통화단위 추가
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId
    let unit = currencyId === 'KRW' ? 10000 : 1
    let _min = null
    // 적용계산유형이 "2"(이상)이고, 기준금액이 min값보다 크면, 기준금액을 기준으로 세팅- 20170220 박세현 추가
    if (zaAplcCalcTypVal === '2' && stndAmt !== 0 && stndAmt > Number(covElem.min)) {
      _min = piCommonUtil.NumCal(stndAmt, unit, '/')
    } else {
      _min = piCommonUtil.NumCal(covElem.min, unit, '/')
    }
    // 계산값을 calMin에 세팅
    covElem.calMin = _min
    return _min
  },
  // 최대값
  calMaxCovInsAmt  (covElem, stndAmt, zaAplcCalcTypVal ) {
    let abdapolicy = Gaf.getGafObjByRefId('POLICY')[0]
    let currencyId = piCommonUtil.isEmpty2(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId
    let unit = currencyId === 'KRW' ? 10000 : 1
    let _max = null
    // 적용계산유형이 "3"(이하)이고, 기준금액이 max값보다 작으면, 기준금액을 기준으로 세팅- 20170220 박세현 추가
    if (zaAplcCalcTypVal === '3' && stndAmt !== 0 && stndAmt < Number(covElem.max)) {
      _max = piCommonUtil.NumCal(stndAmt, unit, '/')
    } else {
      _max = piCommonUtil.NumCal(covElem.max, unit, '/')
    }
    // 계산값을 calMax에 세팅
    covElem.calMax = _max
    return _max
  },

  /**
 * 만나이를 구한다. (AgeUtils.java 참고)
 * @param birthDate 형식 : yyyymmdd
 * @param baseDate  형식 : yyyymmdd
 * @returns
 */
  getFullAge  (birthDate, baseDate) {
    let age = 0

    if (birthDate.substring(0, 6) === '000000') {
      age = 0
    } else {
      try {
        age = Number(baseDate.substring(0, 4)) - Number(birthDate.substring(0, 4))
        if (age > 0) {
          let birthDay = Number(birthDate.substring(4, 8))
          let baseDay = Number(baseDate.substring(4, 8))
          if (birthDay > baseDay) {
            age--
          }
        }
      } catch (e) {
        Gaf.d('this.getFullAge Error -> ', e)
      }
    }
    return age
  },

  /**
 * 주보험 납입기간
 */
  getMnPadPrd  () {
    let _mainCov = Gaf.getVObj('PNLPQL00109_CoverageInfo').filter((item) => { return item.elementprodtpCd === '10' })[0] // 주보험 추출
    if (!piCommonUtil.isObj(_mainCov)) {
      return false
    }

    let result = ''

    let pmprdTypCd = _mainCov.pmprdTypSelected.key.split(',')[0] // 납입기간유형코드
    let pmprd = _mainCov.pmprdTypSelected.key.split(',')[1] // 납입기간

    // 납입기간유형코드='02' - 세형
    if (pmprdTypCd === '02' ) {
      result = piCommonUtil.NumCal(pmprd, this.getCustAge('21'), '-')
    } else {
      result = pmprd
    }

    return result
  },

  /**
 * 관계자 연령
 */
  getCustAge  (zzcontvInsrdCd) {
    let rltnrInfoList = Gaf.getTObj('rltnrInfo')
    let rltnrInfo = rltnrInfoList.filter((item) => { return item.zzcontvInsrdCd === zzcontvInsrdCd })
    if (!piCommonUtil.isObj(rltnrInfo)) {
      return false
    }
    return rltnrInfo[0].ageatentryVl
  }
}
/** ------------------------------------------- Gaf Main Func End   ------------------------------------------- */
