/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI452P
 * 화면설명: 전문금융소비자 입력용
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="전문금융소비자 입력용" :show-title="false" type="popup" ref="page" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_DnlCancelBtn()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '전문금융소비자 입력용' : '전문금융소비자 입력용'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>    
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-agreeArea2 mt30 pb50">
              <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
                <div class="text-gray-box">
                  <ul class="terms-list-area crTy-bk7 fs14rem">
                    <li><div class="crTy-orange2">전문금융소비자가 아닌 경우, 아래 취소 버튼 선택 후 ‘일반금융소비자’ 를 선택해주세요.</div></li>
                  </ul>
                </div> 
              </ur-box-container>  
              <ur-box-container alignV="start" componentid="" direction="column">
                <div class="txt-box">
                  <span class="fwm fs18rem">전문금융소비자여부 확인</span>
                  <div class="ns-check fexTy2 mt16">
                    <mo-checkbox v-model="multiCheckbox1" class="fexInt" value="1" @input="fn_checkQ11(1)"/>
                    <div class="pt2 pl10">국가, 지자체, 공공기관 및 법인·단체 증</div>
                  </div>
                  <div class="mt4 pl35">
                    <ul class="terms-list-area crTy-bk7 fs14rem">
                      <li>금융회사, 지방공기업, 보험관계단체, 5인이상 사업장 등</li>
                    </ul>
                  </div>
                  <div class="ns-check fexTy2 mt16">
                    <mo-checkbox v-model="multiCheckbox1" class="fexInt" value="2" @input="fn_checkQ11(2)"/>
                    <div class="pt2 pl10">보험설계사, 대리점 및 보험중개사 등</div> 
                  </div>
                  <div class="ns-check fexTy2 mt16">
                    <mo-checkbox v-model="multiCheckbox1" class="fexInt" value="9" @input="fn_checkQ11(9)"/>
                    <div class="pt2 pl10">기타</div>
                  </div>  
                </div>
              </ur-box-container>
              <ur-box-container v-if="isShowContent2" alignV="start" componentid="" direction="column" class="mt36">
                <div class="txt-box">
                  <span class="fwm fs18rem">소비자 보호를 위한 일반금융소비자 동일 대우 여부</span>
                  <div class="ns-check fexTy2 mt16">
                    <mo-checkbox v-model="multiCheckbox2" class="fexInt" value="4" @input="fn_checkQ11(4)"/>
                    <div class="pt2 pl10">「금융소비자 보호에 관한 법률 시행령」 제 2조제7항제3호(투자성 상품의 경우 제2호 포함)에 해당하는 전문금융소비자로, 관계법규에 따라 일반금융소비자와 동일한 대우를 받고자 함</div>
                  </div>
                </div>
              </ur-box-container>
            </ur-box-container> 
          </template>
        </mo-collapsing-header-view>


        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_DnlCancelBtn()">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="multiCheckbox1.length===0" @click="fn_DnlConfirmBtn()">확인</mo-button>
          </div>
        </ur-box-container>

      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
export default {
  name: 'MSPPI452P',
  screenId: 'MSPPI452P',
  components: {},
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    'pParam': null
  },
  data () {
    return {
      isShowContent2: true,
      lv_HeaderviewScrollCompID: '',
      multiCheckbox1: [],
      multiCheckbox2: []
    }
  },
  created () {
    console.log('SuitaDnlArray', this.pParam)
    for ( let i = 0; i < this.pParam.length; i++ ) {
      if ( this.pParam[i] === '4' ) {
        this.multiCheckbox2.push('4')
      } else {
        if(this.pParam[i] === '2') {
          this.isShowContent2 = false
        }
        this.multiCheckbox1.push(this.pParam[i])
      }
    }
  },
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  methods: {
    /******************************************************************************
    * Function명 : fn_DnlConfirmBtn
    * 설명       : 확인버튼 선택 시 호출 - 선택한 항목의 값을 이전 창으로 돌려보냄.
    ******************************************************************************/
    fn_DnlConfirmBtn () {
      this.multiCheckbox1 = this.multiCheckbox1.concat(this.multiCheckbox2)
      this.$emit('ExportConfirmData', this.multiCheckbox1)
    },
    /******************************************************************************
    * Function명 : fn_DnlCancelBtn
    * 설명       : 취소버튼 선택 시 호출
    ******************************************************************************/
    fn_DnlCancelBtn () {
      this.$emit('ExportCancelData', false)
    },
    /******************************************************************************
    * Function명 : fn_checkQ11
    * 설명       : 아이템 체크 시 호출
    ******************************************************************************/
    fn_checkQ11 (idx) {
      if (this.multiCheckbox1.length > 1) {
        this.multiCheckbox1 = []
        this.multiCheckbox1.push(idx.toString())
      }
      // 보험설계사, 대리점 및 보험중개사 일때 예외처리
      if(idx === 2) {
        if(this.multiCheckbox1.length < 1) {
          // 선택된 체크가 없다면
          this.isShowContent2 = true
        }else {
          this.multiCheckbox1.forEach(item => {
            if(item === '2') {
              this.isShowContent2 = false
              this.multiCheckbox2 = []
            }
          })
        }
      }else {
        this.isShowContent2 = true
      }
    },

    _fn_End(){return} // 코딩 종료 함수
  }
}
</script>