/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI404P
 * 화면설명: 법인고객조회
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="법인고객 조회" type="popup" ref="page" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <div slot="nav" class="icon-wrapper" @click="fn_Cancel()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '법인고객 조회' : '법인고객 조회'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px"></div>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30"> 
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box"> 
                <mo-text-field v-model="custNm" class="form-input-name full" searchable underline placeholder="고객명을 입력해주세요" @click-icon="fn_InputCustomerNm" @keyup.enter="fn_InputCustomerNm"/>
              </ur-box-container>
            </ur-box-container>  
            <!-- 총명수  -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum mt10">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy5">
                    <span class="sum">총 <strong class="crTy-blue3">{{lv_TotalCnt}}</strong> 명</span>
                    <span class="ns-tool-tip mt7 ml10">
                      <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>
                      <mo-tooltip target-id="location0" class="tool-tip-box">법인명의 변액보험 계약은 선택된 대표자의 진단결과에 따라서 청약서류가 발행됩니다.</mo-tooltip>                  
                    </span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- // 총명수 -->            
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container v-if="status === '0'" alignV="start" componentid="" direction="column" class="ns-radio-list2 list-pa1624">
              <mo-list :list-data="items">
                <template #list-item="{item}">
                  <mo-list-item :class="{'on' : item.chnlCustId === selectedRadio}">
                    <mo-radio v-model="selectedRadio" :value="item.chnlCustId" @input="fn_CheckSelectedItem(item)"/>
                    <div class="list-item__contents" @click="fn_CheckSelectedItem(item)">
                      <div class="list-item__contents__title">
                        <!-- 고객명 -->
                        <span class="name txtSkip">{{item.custNm}}</span>
                        <mo-badge class="badge-box sm lightgray2" text="" shape="status">{{item.mnCustRltnCdNm}}</mo-badge>
                        <!-- // 태그정의 관계: 본인, 배우자, 자녀, 손자, 부모, 조부모, 친척, 기타가족 -->
                      </div>
                      <!-- 생년월일 / 성별 / 나이 -->
                      <div class="list-item__contents__info mt3">
                        <span class="crTy-bk1">{{item.birthNo}}</span><em>|</em><span class="crTy-bk1">{{item.sxdsCdNm}}</span><em>|</em><span class="crTy-bk1">{{item.age}}세</span>
                      </div>
                      <div class="list-item__contents__info">
                        <span class="crTy-bk1">필수컨설팅 <b class="ml10" :class="{'crTy-blue2': item.infoPrcusMcnstYn === 'Y', 'crTy-red2': item.infoPrcusMcnstYn === 'N'}">{{item.infoPrcusMcnstYn}}</b></span><em>|</em>
                        <span class="crTy-bk1">마케팅 <b class="ml10" :class="{'crTy-blue2': item.infoPrcusMktYn === 'Y', 'crTy-red2': item.infoPrcusMktYn === 'N'}">{{item.infoPrcusMktYn}}</b></span>
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>   
            </ur-box-container>

            <!-- no-data -->  
            <ur-box-container v-else-if="status === '1'" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space">
                    <span class="ns-ftcr-gray"><p class="mt20">고객명 입력 후 검색해 주세요</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <!-- no-data --> 
            <ur-box-container v-else-if="status === '2'" alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space">
                    <span class="ns-ftcr-gray"><p class="mt20">데이터가 없습니다.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container> 
          </template>
        </mo-collapsing-header-view>


        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel()">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmContent()" :disabled="$bizUtil.isEmpty(selectedRadio)">확인</mo-button>
          </div>
        </ur-box-container>
      </div>

      <!--START: confirm alert -->
      <mo-bottom-sheet ref="refConfirm" class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AlertClose('cancel')">{{alertBtnLb1}}</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AlertClose('ok')">{{alertBtnLb2}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: confirm alert -->

    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPPI404P',
  screenId: 'MSPPI404P',
  components: {},
  props: {'pCustNm': String}, // 세대원 정보 VO
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_HeaderviewScrollCompID: '',
      hideIndicator: true,
      custNm: '', // 고객명
      selectedItem: {}, // 선택항목
      items: [], // 법인 고객 검색 후 고객 리스트 저장 변수
      selectedRadio: '', // 선택한 고객 인덱스 값
      isLoading: false, // 데이터가 응답 오기 전인지 여부
      btnConfirmDisabled: true,
      status: '2', // 0: 리스트, 1: 고객명을 입력후..., 2: 데이터가 없습니다.
      lv_TotalCnt: 0,
      alertMsg: '필수컨설팅동의를 받으신 고객만 적합성진단이 가능합니다.\n필수컨설팅동의로 이동하시겠습니까?',
      alertBtnLb1: '취소',
      alertBtnLb2: '고객등록동의'
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},
  /** watch 정의 영역 */
  watch: {
    selectedItem (item) {
      if (item !== null) {
        this.selectedRadio = item.chnlCustId.toString()

        if ( item.infoPrcusMcnstYn === 'N' ) {
          this.$refs.refConfirm.open()
          return
        }

        this.btnConfirmDisabled = false
      }
    }
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 고객명이 파라미터로 넘어온 경우
    if (this.pCustNm !== null && this.pCustNm !== '') {
      this.custNm = this.pCustNm
      this.fn_Init()
    }
  },
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : row 선택 시 호출. 선택된 row의 radio value를 변경
     ******************************************************************************/
    fn_CheckSelectedItem (item) {
      this.selectedItem = item // 선택값 저장
      this.selectedRadio = item.chnlCustId
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 최초 로딩 시 전달 값을 확인하여 설정한다
    ******************************************************************************/
    fn_Init() {
      this.fn_InputCustomerNm()
    },
    /******************************************************************************
    * Function명 : fn_InputCustomerNm
    * 설명       : 고객명 Text Input 클릭 시 호출 - 이름 명 검색
    ******************************************************************************/
    fn_InputCustomerNm () {
      // 초기화 진행 필요
      this.items.splice(0, this.items.length)
      this.lv_TotalCnt = 0
      this.selectedRadio = ''

      if ( this.custNm === undefined || this.custNm === '' || ( this.custNm.length > 0 && this.custNm.trim() === '')) {
        this.status = '1'
      } else {
        this.fn_SearchCustomerInfo()
      }
    },
    /******************************************************************************
    * Function명 : fn_SearchCustomerInfo
    * 설명       : 고객명으로 고객 정보활용 동의 정보 조회 서비스 호출
    ******************************************************************************/
    fn_SearchCustomerInfo () {
      let lv_Vm = this
      lv_Vm.isLoading = true
      lv_Vm.btnConfirmDisabled = true

      let pParams = {custNm: lv_Vm.custNm.trim()} // 고객명
      if ( pParams.custNm === '' ) {
        this.status = '1'
        return
      }

      let trnstId = 'txTSSCM10S4'
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          // 필수 컨설팅 리스트
          if (response.body !== null) {
            if (response.body.cMInfoPrcusCnsntListVO !== null) {
              lv_Vm.items = response.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.items.length > 0) {
                lv_Vm.status = '0'
              } else {
                lv_Vm.status = '2'
              }
              lv_Vm.lv_TotalCnt = lv_Vm.items.length
            } else {
              lv_Vm.items.splice(0, lv_Vm.items.length)
              lv_Vm.selectedRadio = ''
              lv_Vm.status = '2'
              lv_Vm.lv_TotalCnt = 0
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
          window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
        })
        .catch(function (error) {
          window.vue.error(error)
          window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
        }
      )
    },
    /******************************************************************************
    * Function명 : fn_AlertClose
    * 설명       : 필수컨설팅 미동의 알림 알럿 닫음
    ******************************************************************************/
    fn_AlertClose(val) {
      this.$refs.refConfirm.close()
      if(val === 'ok') {
        // MSPCM002M 으로 이동
        this.$emit('cancelPopup', {action: 'goCM', param: this.selectedItem} )
      }else {
      }
    },
    /******************************************************************************
    * Function명 : fn_ConfirmContent
    * 설명       : 확인버튼 선택 시 호출 - 선택한 항목의 값을 Main 창으로 돌려보냄.
    ******************************************************************************/
    fn_ConfirmContent () {
      if ( this.selectedItem.infoPrcusMcnstYn === 'N' ) {
        this.$refs.refConfirm.open()
        return
      }
      this.$emit('confirmPopup', this.selectedItem)
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소버튼 선택 시 호출  - 팝업창 닫는 이벤트 emit
    ******************************************************************************/
    fn_Cancel () {
      this.$emit('cancelPopup')
    },

    _fn_End(){return} // 코딩 종료 함수
  }
}
</script>